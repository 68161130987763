import { SpecialMealDto } from "../modules/Special-Meal/dtos/special-meal.dto";
import { Api } from "../resources/api-constants"
import CustomAxios from '../utility/customAxios';

export const getSpecialMealService = (id: string) => {
	return CustomAxios.get(`${Api.GET_SPECIAL_ORDER}/${id}`)
}

export const createSpecialMealService = (data: SpecialMealDto) => {
	return CustomAxios.post(`${Api.CREATE_SPECIAL_ORDER}`, data)
}


export const selectSpecialOfferService = (specialOfferId: string, kitchenId: string) => {
	return CustomAxios.put(`${Api.SELECT_SPECIAL_OFFER}/${specialOfferId}/${kitchenId}`)
}
