import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import NotFoundPage from './modules/NotFoundPage'
import { ROUTES } from './resources/routes-constants'
import AuthGuard from './Guards/Auth.Guard'

const RootComponent: React.FC = () => {
	const LayoutComponent = React.lazy(() => import('./layout/Layout.component'))
	const HomePage = React.lazy(() => import('./modules/home/Home.page'))
	const LoginPage = React.lazy(() => import('./modules/Auth/pages/Login.page'))
	const ContactUsPage = React.lazy(() => import('./modules/contatc-us/ContactUs.page'))
	const AboutUsPage = React.lazy(() => import('./modules/about-us/AboutUs.page'))
	const CartPage = React.lazy(() => import('./modules/cart/Cart.page'))
	const CheckoutPage = React.lazy(() => import('./modules/Checkout/Checkout.page'))
	const ProfilePage = React.lazy(() => import('./modules/profile/profile.page'))
	const FavoritePage = React.lazy(() => import('./modules/favorite/Favorite.Page'))
	const SignupPage = React.lazy(() => import('./modules/Auth/pages/Signup.page'))
	const KitchenDetailsPage = React.lazy(() => import('./modules/kitchens/Kitchen.details'))
	const KitchensPage = React.lazy(() => import('./modules/kitchens/Kitchens.page'))
	// const MenuPage = React.lazy(() => import('./modules/menu/Menu.Page'))
	const MenuDetailsPage = React.lazy(() => import('./modules/menu/Menu.details.page'))
	const HowToOrderPage = React.lazy(() => import('./modules/how-to-order/HowToOrder.page'))

	return (
		<BrowserRouter basename="/">
			<Suspense fallback={<></>}>
				<Routes>
					<Route path={ROUTES.HOMEPAGE} element={<LayoutComponent />}>
						<Route path="*" element={<NotFoundPage />} />
						<Route index element={<HomePage />} />
						<Route path={ROUTES.LOGIN} element={<LoginPage />} />
						<Route path={ROUTES.SIGNUP} element={<SignupPage />} />
						<Route path={ROUTES.KITCHENS} element={<KitchensPage />} />
						<Route path={ROUTES.KITCHEN_DETAILS} element={<KitchenDetailsPage />} />
						{/* <Route path={ROUTES.MENU + '/:id?'} element={<MenuPage />} /> */}
						<Route path={ROUTES.MENU_DETAILS} element={<MenuDetailsPage />} />
						<Route path={ROUTES.CONTACT_US} element={<ContactUsPage />} />
						<Route path={ROUTES.ABOUT_US} element={<AboutUsPage />} />
						<Route path={ROUTES.HOW_TO_ORDER} element={<HowToOrderPage />} />
						<Route path={ROUTES.CART} element={<CartPage />} />
						<Route path={ROUTES.CHECKOUT} element={<AuthGuard><CheckoutPage /></AuthGuard>} />
						<Route path={ROUTES.PROFILE} element={<AuthGuard><ProfilePage /></AuthGuard>} />
						<Route path={ROUTES.FAVORITE} element={<AuthGuard><FavoritePage /></AuthGuard>} />
					</Route>
				</Routes>
			</Suspense>
		</BrowserRouter>
	)
}

export default RootComponent
