import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import RootComponent from './RootComponent'
import {  store,persistor } from './store/store'
import { languageInitialization } from './utility/language'
import { AuthProvider } from './providers/Auth.provider'
import { UserProvider } from './providers/User.provider'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { LoadingProvider } from './providers/IsLoading.provider'

const App: React.FC = () => {
	languageInitialization()

	return (
		<GoogleOAuthProvider clientId="775378178026-3omv5pso6u999b57icu029suv4mnve71.apps.googleusercontent.com">
			<Suspense fallback={<></>}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<AuthProvider>
							<UserProvider>
								<LoadingProvider>
									<RootComponent />
								</LoadingProvider>
							</UserProvider>
						</AuthProvider>
					</PersistGate>
				</Provider>
			</Suspense>
		</GoogleOAuthProvider>
	)
}

export default App
