import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../resources/routes-constants'

const NotFoundPage: React.FC = () => {
	return (
		<section className="error-404">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-12">
						<div className="error">
							<img src="/assets/images/error.svg" />
							<h1>Oops! Page Not Found</h1>
							<p>
								We do not know how yo come to here!
								<br />
								But you can go back home.
							</p>
							<Link to={ROUTES.HOMEPAGE} className="home-btn btn-link">
								Go To Homepage
							</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default NotFoundPage
